import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import DatabaseNavigation from './DatabaseNavigation';
import './database.css';
import './Daterange.css';

const options = {
  responsive: true,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  scales: {
    x: {
      title: {
        display: true,
        text: 'Time Frame',
      },
      grid: {
        color: 'rgba(200, 200, 200, 0.2)',
      },
    },
    y: {
      type: 'linear',
      display: true,
      position: 'left',
      title: {
        display: true,
        text: 'Retainers',
      },
      grid: {
        color: 'rgba(200, 200, 200, 0.2)',
      },
    },
    y1: {
      type: 'linear',
      display: true,
      position: 'right',
      title: {
        display: true,
        text: 'Output',
      },
      grid: {
        drawOnChartArea: false, 
      },
    },
  },
};

const DatabaseOutput = ({ selectedAgent, selectedCampaign, fromDate, toDate, timeFrame, onAgentChange, onCampaignChange, onTimeFrameChange }) => {
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [agents, setAgents] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [message, setMessage] = useState('');


  useEffect(() => {
    if (fromDate && toDate && selectedAgent && selectedCampaign && timeFrame) {
      fetchChartData(selectedAgent, selectedCampaign);
    } else {
      setMessage('Please select a time frame and date range to display data.');
    }
  }, [fromDate, toDate, selectedAgent, selectedCampaign, timeFrame]);


  useEffect(() => {
    fetchAgents();
    fetchCampaigns();
  }, []);

  useEffect(() => {
    if (selectedAgent && selectedCampaign) {
      fetchChartData(timeFrame, selectedAgent, selectedCampaign);
    }
  }, [timeFrame, selectedAgent, selectedCampaign]);

  const fetchAgents = async () => {
    try {
      const token = localStorage.getItem('ViribuzAccessToken');
      if (!token) {
        console.error('No access token found in localStorage');
        return;
      }

      const excludedEmails = [
        'admin@mail.com',
        'casper@mail.com',
        'agent@mail.com',
        'testagentx@agent.com',
        'testagenty@agent.com',
        'string',
        'rogeradmin@agent.com'
      ];

      const response = await axios.get('http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgent/GetAgents', {
        headers: {
          'ViribuzAccessToken': token,
        }
      });

      const filteredAgents = response.data.data
        .filter(agent => !agent.isAdmin)
        .filter(agent => !excludedEmails.includes(agent.email));

      setAgents(filteredAgents);
    } catch (error) {
      console.error('Error fetching agents:', error);
    }
  };

  const fetchCampaigns = async () => {
    try {
      const response = await axios.get('http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentMain/GetCampaigns');
      const sortedCampaigns = response.data.campaigns.sort((a, b) => a.name.localeCompare(b.name));
      const allCampaignOption = { id: '0', name: 'All Campaigns' };
      setCampaigns([allCampaignOption, ...sortedCampaigns]);
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    }
  };

  const getDateRange = (timeFrame, dateRange = null) => {
    const today = moment();
    let fromDate, toDate;

    if (dateRange) {
      fromDate = moment(dateRange.fromDate).format('YYYY-MM-DD');
      toDate = moment(dateRange.toDate).format('YYYY-MM-DD');
    } else {
      if (timeFrame === 'months') {
        fromDate = today.clone().subtract(11, 'months').startOf('month').format('YYYY-MM-DD');
        toDate = today.clone().endOf('month').format('YYYY-MM-DD');
      } else if (timeFrame === 'weeks') {
        fromDate = today.clone().subtract(4, 'weeks').startOf('week').format('YYYY-MM-DD');
        toDate = today.clone().endOf('week').format('YYYY-MM-DD');
      } else if (timeFrame === 'days') {
        fromDate = today.clone().startOf('month').format('YYYY-MM-DD');
        toDate = today.clone().endOf('month').format('YYYY-MM-DD');
      }
    }

    console.log(`Calculated date range for ${timeFrame}: From ${fromDate} to ${toDate}`);
    return { fromDate, toDate };
  };


  const fetchChartData = async (agentId = '0', campaignId = '0') => {
    if (!fromDate || !toDate || !timeFrame) {
      setMessage('Please select a time frame and date range to display data.');
      setChartData({ labels: [], datasets: [] });
      return;
    }

    const fromDateFormatted = moment(fromDate).format('YYYY-MM-DD');
    const toDateFormatted = moment(toDate).format('YYYY-MM-DD');

    try {
      const response = await axios.get(
        `http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentDatabase/GetLineChartByDateRange?DateFrom=${fromDateFormatted}&DateTo=${toDateFormatted}&agentId=${agentId}&campaignId=${campaignId}`
      );
      const data = response.data;

      if (!data || data.length === 0) {
        setChartData({ labels: [], datasets: [] });
        setMessage('No data available for the selected criteria.');
        return;
      }

      setMessage('');

      if (timeFrame === 'weeks') {
        const groupedData = {};
        data.forEach(item => {
          const retainedDate = moment(item.RetainedDate);
          if (retainedDate.isValid()) {
            const weekKey = `Week ${retainedDate.week()} (${retainedDate.year()})`;
            if (!groupedData[weekKey]) {
              groupedData[weekKey] = { output: 0, retainers: 0 };
            }
            groupedData[weekKey].output += item.TotalPhoneCallsMade + item.TotalMessagesSent + item.TotalEmailsSent;
            groupedData[weekKey].retainers += item.TotalRetainersAcquired;
          }
        });

        const sortedWeekKeys = Object.keys(groupedData).sort((a, b) => {
          const [aWeek, aYear] = a.match(/Week (\d+) \((\d+)\)/).slice(1).map(Number);
          const [bWeek, bYear] = b.match(/Week (\d+) \((\d+)\)/).slice(1).map(Number);
          return aYear === bYear ? aWeek - bWeek : aYear - bYear;
        });

        setChartData({
          labels: sortedWeekKeys,
          datasets: [
            {
              type: 'line',
              label: 'Retainers',
              data: sortedWeekKeys.map(key => groupedData[key].retainers),
              borderColor: 'rgba(255, 99, 132, 1)',
              backgroundColor: 'rgba(255, 99, 132, 0.5)',
              yAxisID: 'y',
            },
            {
              type: 'bar',
              label: 'Output',
              data: sortedWeekKeys.map(key => groupedData[key].output),
              backgroundColor: 'rgba(75, 192, 192, 0.5)',
              yAxisID: 'y1',
            },
          ],
        });

      } else if (timeFrame === 'months') {
        const months = Array.from({ length: 12 }, (_, i) =>
          moment().subtract(i, 'months').format('MMMM YYYY')
        ).reverse();
        const groupedData = months.reduce((acc, month) => {
          acc[month] = { output: 0, retainers: 0 };
          return acc;
        }, {});

        data.forEach(item => {
          const month = moment(item.RetainedDate).format('MMMM YYYY');
          if (groupedData[month]) {
            groupedData[month].output += item.TotalPhoneCallsMade + item.TotalMessagesSent + item.TotalEmailsSent;
            groupedData[month].retainers += item.TotalRetainersAcquired;
          }
        });

        setChartData({
          labels: months,
          datasets: [
            {
              type: 'line',
              label: 'Retainers',
              data: months.map(month => groupedData[month].retainers),
              borderColor: 'rgba(255, 99, 132, 1)',
              backgroundColor: 'rgba(255, 99, 132, 0.5)',
              yAxisID: 'y',
            },
            {
              type: 'bar',
              label: 'Output',
              data: months.map(month => groupedData[month].output),
              backgroundColor: 'rgba(75, 192, 192, 0.5)',
              yAxisID: 'y1',
            },
          ],
        });

      } else if (timeFrame === 'days') {
        const labels = Array.from(new Set(data.map(item => new Date(item.RetainedDate).toLocaleDateString())));
        const output = labels.map(label =>
          data
            .filter(item => new Date(item.RetainedDate).toLocaleDateString() === label)
            .reduce((sum, item) => sum + item.TotalPhoneCallsMade + item.TotalMessagesSent + item.TotalEmailsSent, 0)
        );
        const retainers = labels.map(label =>
          data
            .filter(item => new Date(item.RetainedDate).toLocaleDateString() === label)
            .reduce((sum, item) => sum + item.TotalRetainersAcquired, 0)
        );

        setChartData({
          labels,
          datasets: [
            {
              type: 'line',
              label: 'Retainers',
              data: retainers,
              borderColor: 'rgba(255, 99, 132, 1)',
              backgroundColor: 'rgba(255, 99, 132, 0.5)',
              yAxisID: 'y',
            },
            {
              type: 'bar',
              label: 'Output',
              data: output,
              backgroundColor: 'rgba(75, 192, 192, 0.5)',
              yAxisID: 'y1',
            },
          ],
        });
      }
    } catch (error) {
      console.error('Error fetching chart data:', error);
      setChartData({
        labels: [],
        datasets: [],
      });
    }
  };



  return (
    <div className="container-fluid" style={{ marginBottom: '1rem', maxHeight: '400px' }}>
      <Container fluid style={{ paddingBottom: '1rem' }}>
        <h3 style={{ color: '#a2a9b2' }}>Retainers Vs. Output Over Time</h3>
        <Row>
          <Col>
            {message ? (
              <p style={{ color: 'red', textAlign: 'center', padding: '1rem' }}>{message}</p>
            ) : (
              <div style={{
                height: '450px',
                width: '100%',
                overflowX: 'auto',
                overflowY: 'hidden',
                whiteSpace: 'nowrap',
                margin: '0 auto',
                paddingBottom: '1rem',
              }}>
                <div style={{ minWidth: '800px', height: '100%' }}>
                  <Bar options={options} data={chartData} />
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DatabaseOutput;
